<template>
  <div class="px-3">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="row" @submit.prevent="handleSubmit(submit)">
        <div class="col-sm-12 text-start" v-if="course">
          <div class="row mb-4">
            <div class="form-group col-md-6 mb-2">
              <label for="userName" class="text-muted mb-2">課程名稱</label>
              <ValidationProvider rules='required' v-slot='{ errors , classes }'>
                <div :class="classes">
                  <input type="text" class="form-control" id="courseName"
                    v-model="course.name"
                    placeholder="課程名稱">
                  <span class="text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-6 mb-2">
              <label for="account" class="text-muted mb-2">價格</label>
              <ValidationProvider rules='required' v-slot='{ errors , classes }'>
                <div :class="classes">
                  <input type="number" class="form-control" id="price"
                    v-model="course.price"
                    placeholder="價格"
                    >
                  <span class="text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row mb-4">
            <!-- <div class="form-group col-md-6 mb-2">
              <label for="email" class="text-muted mb-2">Email</label>
              <ValidationProvider rules='email' v-slot='{ errors , classes }'>
                <div :class="classes">
                  <input type="email" class="form-control" id="email"
                    v-model="user.email"
                    placeholder="email"
                  >
                  <span class="text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div> -->
            <div class="form-group col-md-6 mb-2">
              <label class="form-label text-start d-inline-block w-100">課程分類</label>
              <select class="form-select" aria-label="courses__filter" v-model="nowcategoryId">
                <option disabled value>請選擇分類</option>
                <option :value="item.id" v-for="item in categories" :key="item.id">{{ item.name }}</option>
              </select>
              <!-- <span class="invalid text-danger mt-1" v-if="categoryError">{{ categoryError }}</span> -->
            </div>
            {{nowcategoryId}}
            <div class="form-group col-md-6 mb-5">
              <label class="form-label text-start d-inline-block w-100">教師</label>
              <select class="form-select" aria-label="courses__author" v-model="course.authorId">
                <option disabled value>請選擇教師</option>
                <option :value="item.id" v-for="item in teacherList" :key="item.id">{{ item.userName }}</option>
              </select>
              <!-- <span class="invalid text-danger mt-1" v-if="categoryError">{{ categoryError }}</span> -->
            </div>
          </div>
          <div class="row mb-4">
            <div class="form-group col-md-6 mb-2">
              <label for="detail" class="form-label text-start d-inline-block w-100">課程描述</label>
                <ValidationProvider rules='required' v-slot='{ errors, classes }'>
                  <div :class="classes">
                    <textarea
                      v-model="course.detail"
                      rows="10"
                      name="detail"
                      class="form-control mb-1"
                      required>
                    </textarea>
                    <span class="text-danger mt-1">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
            </div>
            <div class="form-group col-md-6 mb-2">
              <label for="prevImageUrl" class="form-label text-start d-inline-block">課程圖片</label>
              <div class="courses__cover admin mx-auto" ref="img" v-if="!course.prevImageUrl || course.prevImageUrl == 'NotSet'">

              </div>
              <div class="courses__cover admin mx-auto" ref="img" v-if="course.prevImageUrl && course.prevImageUrl !== 'NotSet'" :style="{ backgroundImage: `url(${course.prevImageUrl})`}">

              </div>
              <label for="cover" class="w-100 text-center"><span class="btn btn-main-outline mb-2 admin">上傳課程封面圖片</span></label>
              <input type="file" name="cover" id="cover" class="d-none" ref="files" @change="getUploaded">
              <input type="hidden" v-model='course.prevImageUrl'>
            </div>

          </div>
          <div class="row mb-4">
            <div class="form-group col-md-6 mb-2">
              <label for="prevImageUrl1" class="form-label text-start d-inline-block w-100">教材書本圖片</label>
              <img src="../assets/images/coverImg.svg" style="width: 45%;" alt="" ref="bookImg" v-if="!course.prevImageUrl1 || course.prevImageUrl1 == 'NotSet'">
              <img :src="course.prevImageUrl1" alt="" style="width: 45%;" ref="bookImg" v-if="course.prevImageUrl1 && course.prevImageUrl1!== 'NotSet'">
              <label for="bookcover" class="w-100"><span class="btn btn-main-outline mb-2 admin bookcover">上傳書本圖片</span></label>
              <input type="file" name="bookcover" id="bookcover" class="d-none" ref="bookfiles" @change="getBookUploaded">

              <input type="hidden" v-model='course.prevImageUrl1'>
            </div>
            <div class="form-group col-md-6 mb-5">
              <label for="lockout" class="mb-2">課程啟用</label>
              <div class="form-check mt-1">
                <input class="form-check-input" type="checkbox"
                  v-model="course.ena"
                  id="is_enabled">
                <label class="form-check-label mt-1" for="is_enabled">
                  啟用
                </label>
              </div>
            </div>

            <div class="col-md-12 text-end">
              <button class="btn btn-main" type="submit">儲存</button>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

const Swal = require('sweetalert2');

export default {
  name: 'Admin__Course',
  data() {
    return {
      courseId: 0,
      categories: [],
      teacherList: [],
      courseInfo: {},
      nowcategoryId: 0,
      nowAuthorId: '2d7267d4-8dad-454c-bae0-f0b30772b444',
      course: {
        name: '',
        detail: '',
        prevImageUrl: '',
        prevImageUrl1: '',
        price: 0,
        categoryId: 0,
        authorId: '',
        ena: true,
        selected: true,
        authorName: '',
        hashtags: [],
      },
      metaTitle: '課程編輯 / 課程 / 管理 - 元學堂',
    };
  },
  metaInfo: {
    title: "課程編輯 / 課程 / 管理 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '課程編輯 / 課程 / 管理 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    getData() {
      const vm = this;
      this.updateLoading(true);
      this.$http.get(`${this.$API_PATH}/Admin/Course/Management/${this.courseId}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        // console.log(res.data);
        this.course = res.data.data.course;
        this.course.hashtags = res.data.data.hashtags;
        this.course.prevImageUrl == 'NotSet' || '' ? 'NotSet' : decodeURIComponent(this.course.prevImageUrl);
        this.nowcategoryId = this.course.categoryId;
        this.nowAuthorId = this.course.authorId;
        this.course.prevImageUrl1 = decodeURIComponent(res.data.data.coursePrevImgUrl1);

        this.updateLoading(false);
      }).catch((err) => {
        this.updateLoading(false);
        // console.log(err);
        this.$log.debug(err.response);
      });
    },
    submit() {
      const vm = this;
      const data = {
        courseName: this.course.name,
        detail: this.course.detail,
        prevImageUrl: this.course.prevImageUrl,
        prevImageUrl1: this.course.prevImageUrl1,
        price: this.course.price,
        authorId: this.course.authorId,
        ena: this.course.ena,
        categoryId: this.nowcategoryId,
      };
      // console.log(data);
      this.updateLoading(true);
      vm.$http.put(`${this.$API_PATH}/Admin/Course/Management/${this.courseId}`, data, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.updateLoading(false);
        Swal.fire(
          '儲存成功',
          `${res.data.message}`,
          'success',
        ).then((result) => {
          if (result.isConfirmed) {
            vm.$router.push('/admin/courses');
          }
        });
      }).catch((err) => {
        this.updateLoading(false);
        if (err.response.status == 401) {
          Swal.fire(
            '',
            '無權限取得列表',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              vm.$router.push('/');
            }
          });
        } else if (err.response.status == 404) {
          Swal.fire(
            '',
            '找不到對應的課程資料',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              vm.$router.push('/');
            }
          });
        }
      });
    },
    getCategories() {
      this.$http.get(`${this.$API_PATH}/Course/Categories`, {
        headers: {
          Accept: '*/*',
        },
      }).then((res) => {
        this.categories = res.data.data.courseCategories;
        this.$log.debug(this.categories);
      }).catch((err) => {
        this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    getTeachers() {
      this.$http.get(`${this.$API_PATH}/Admin/TeacherList`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.teacherList = res.data.data.teacherList;
        this.$log.debug(this.teacherList);
      }).catch((err) => {
        this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    getUploaded(e) {
      // this.$refs.img.style.backgroundImage = `url(${URL.createObjectURL(this.$refs.files.files[0])})`;
      const uploadedFile = this.$refs.files.files[0];
      this.$log.debug(uploadedFile);
      const vm = this;
      const formData = new FormData();
      formData.append('upload', uploadedFile);
      this.$http.post(`${this.$API_IMGPATH}/Course`, formData)
        .then((response) => {
          vm.$log.debug(response.data);
          vm.course.prevImageUrl = response.data.url;
          vm.$refs.img.style.backgroundImage = `url(${response.data.url})`;
        }).catch((err) => {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          );
          vm.$log.debug(err.response);
        });
    },
    getBookUploaded() {
      // this.$refs.img.style.backgroundImage = `url(${URL.createObjectURL(this.$refs.files.files[0])})`;
      const uploadedFile = this.$refs.bookfiles.files[0];
      this.$log.debug(uploadedFile);
      const vm = this;
      const formData = new FormData();
      formData.append('upload', uploadedFile);
      this.$http.post(`${this.$API_IMGPATH}/CourseBook`, formData)
        .then((response) => {
          vm.$log.debug(response.data);
          vm.course.prevImageUrl1 = response.data.url;
          vm.$refs.bookImg.src = `${response.data.url}`;
        }).catch((err) => {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          );
          vm.$log.debug(err.response);
        });
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userAuth', 'LoginProvider']),
  },
  created() {
    this.courseId = this.$route.params.id;
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    // console.log(this.userAuth);
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    }
    this.getCategories();
    this.getTeachers();
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
  .invalid .text-danger {
    text-align: left;
    width: 100%;
    display: block;
    font-size: 12px;
  }

  .invalid input {
    border: 1px #EB0600 solid;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(252, 67, 21, 0.25);
    }
  }

  .rotate {
    transform: rotate(180deg);
  }
</style>
